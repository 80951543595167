<template>
  <div>
    <Navbar />
    <div class="login_page">
      <!-- <b-col
      lg="8"
      class="d-flex  auth-bg"
    > -->
      <div>
      <b-img
        id="loginImg"
        src="./image.svg"
        class="fit-height"
        alt="Login V2" 
      />
      </div>
      <div style="marginRight: 6rem">
         <b-card-text style="font-size: large; color: black; marginTop: 6rem;" class="mb-2">
          <b>Login to Your Indian Cart</b>
         </b-card-text>

         <!-- form -->
         <validation-observer ref="loginValidation">
           <b-form
             class="auth-login-form mt-2"
             @submit.prevent="login"
           >
             <div class="errors">
               <span class="badge badge-danger" v-if="error">
                 <span v-if="error=='NotAllowedError: User not verified'">
                   User not verified. Please check email and verify your account.
                 </span>
                 <span v-else>

                   {{error}}
                 </span>
               </span>
             </div>
             <b-form-group
               label="Email"
               label-for="login-email"
             >
               <validation-provider
                 #default="{ errors }"
                 name="Email"
                 rules="required|email"
               >
                 <b-form-input
                   id="email"
                   v-model="userEmail"
                   :state="errors.length > 0 ? false:null"
                   name="login-email"
                   placeholder="john@example.com"
                 />
                 <small class="text-danger">{{ errors[0] }}</small>
               </validation-provider>
             </b-form-group>

             <b-form-group>
               <div class="d-flex justify-content-between">
                 <label for="login-password">Password</label>
               </div>
               <validation-provider
                 #default="{ errors }"
                 name="Password"
                 rules="required"
               >
               <!-- <password v-model="password" :toggle="true" required /> -->

                 <div
                 class="Password"
                   :class="errors.length > 0 ? 'is-invalid':null"
                 >

                   <password
                   :toggle="true"
                   id="password"
                     v-model="password"
                     :state="errors.length > 0 ? false:null"
                     :type="password"
                     name="login-password"
                     placeholder="············"
                     :showStrengthMeter=false
                     :badge="false"
                   />
                   
             </div>
                 <small class="text-danger">{{ errors[0] }}</small>
               </validation-provider>
             </b-form-group>



             <!-- submit buttons -->
             <b-button
               type="submit"
               vaiant=""
               style="
               background-color: #E87613 !important;
               width: 100px !important;
               border-radius: 0% !important;
               "
               
               block
             >
               Log in
             </b-button>
             <br>
             <b-link :to="{name:'forgot-password'}">
                 <small>Forgot Password?</small>
               </b-link>
           </b-form>
         </validation-observer>

         <b-card-text style="font-size: large;" class="text mt-2">
           <b><span style="color: black;">Don't Have an account ? </span></b>
           <b-link to="/register-demo">
             <b><span style="color: #4180F1;">&nbsp;Sign Up</span></b>
           </b-link>
         </b-card-text>
      </div>

      <!-- </div>
      
          
        </b-col>
      </b-col>
      </div> -->
      </div>
    <FooterYic />

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Navbar from "@/yicpages/navbar/Navbar.vue";
import { fromArc } from "zrender/lib/core/bbox";
import axios from 'axios'
import Password from "vue-password-strength-meter";
import FooterYic from '../homepage/footer.vue'

import { mapMutations, mapActions, mapState } from "vuex";

export default {
  components: {
    Navbar,
    BRow,
    Password,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    FooterYic
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2-dark.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/yicpages/login/image.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapMutations({ setAuthPayload: "login/SET_AUTHPAYLOAD" }),
    ...mapActions({ loginUser: "login/loginUser",loginUserWithJwt: "login/loginUserWithJwt"}) ,
    async login() {
      const formdata={
        email:this.userEmail.toLowerCase(),
        password:this.password
      }
      console.log("FORM data", formdata)
      this.setAuthPayload(formdata)
      await this.loginUser() 
      if(this.isAuthenticated) {
        console.log(this.$route.params)
        if (this.$route.params.redirectTo){

          this.$router.push(this.$route.params.redirectTo)
        }
        else{
          this.$router.push({name: 'dashboardLanding'})

        }
        }
        // else{
        // await this.loginUserWithJwt() 

        // }
         
    },
  },
  computed: mapState({
    isAuthenticated: (state) => { return state.login.isAuthenticated },
    error: (state) => { return state.login.error },
    isLoading: (state) => { return state.login.isLoading }
  }),
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.auth-wrapper{
  
  width: 100%;
  background-color: white;
}
.fit-height {
  margin-top: -22rem;
  width: 50rem;
  height:80rem;
}
.Password{
  margin-left: 0px;
}

#email{
max-width: 400px;
}
#password{
height: 40px;
border-radius: 5px;
  border: 1px solid #d8d6de

}
#password:focus{
  outline: 1px solid #6610f2

}
.login_page {
  background-color: #ffffff;
  margin-bottom: -33vh;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6rem;
  padding-top: 0;
  padding-bottom: 0;
}
// ********Responsive*********
@media (max-width: 1000px) { 
  #loginImg{
    display: none;
} 
.auth-wrapper{
  padding-top: 50px;
  width: 100%;
  background-color: white;
}
.login_page {
  background-color: #ffffff;
  margin-bottom: 1rem;
  margin-top: 1rem;
  height: fit-content;
}
}
</style>